import React, { Component } from 'react';
import Login from './Login';
import Invite from './Invite';
import ForgotUser from './ForgotUsername';
import ForgotPass from './ForgotPassword';
import Banner from './Banner';
import Verify from './pages/Verify';
import ResetPass from './pages/ResetPassword';

import Feed from './pages/Feed';
import Directory from './pages/Directory';
import Profile from './pages/Profile';
import Collages from './pages/Collages';
import NewCollage from './pages/NewCollage';
import ViewCollage from './pages/ViewCollage';
import EditCollage from './pages/EditCollage';
import Guestbook from './pages/Guestbook';
import Wiki from './pages/Wiki';
import WikiPage from './pages/WikiPage';
import Rules from './pages/Rules';
import EditProfile from './pages/EditProfile';
import Inbox from './pages/Inbox';
import BoolAlert from './pages/BoolAlert';
import Bandcamp from './pages/Bandcamp';


import { Form, FormGroup, Label, Input, Button, Container } from '../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import { axios } from '../utils/axios';

import './pages/MainPage.css';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      loggingOut: false
    };

    this.logout = this.logout.bind(this);
  }

  switchState(e, state) {
    this.setState({flow: state});
  }

  logout() {
    axios.get('logout')
      .then( res => {
        this.props.logOut();
      })
      .catch( err => {

      });
  }

  render() {
    return (
      <div className="App">

        <div className="MainEnterWrapper">

      	  <Container className='MainHeaderContainer'>

	    <Link to='/'>
              <div className="Enter">
                <div className="Title"><i><tt>b o o l . r s</tt></i></div>
              </div>
	    </Link>

	    <hr/>

            <div className='navBar'>
              <NavLink activeClassName='selected' exact='true' to='/'>Home</NavLink>
              <NavLink activeClassName='selected' to='/directory'>Directory</NavLink>
              <NavLink activeClassName='selected' to='/guestbook'>Guestbook</NavLink>
	      <NavLink activeClassName='selected' to='/collages'>Collages</NavLink>
              <NavLink activeClassName='selected' to='/wiki'>Wiki</NavLink>
            </div>

            <hr id='smallHr'/>
            <div className='navBar'>
              <a href="https://deluge.bool.rs" target="_blank">Deluge</a>
              <a href="https://plex.bool.rs" target="_blank">Plex</a>
	      <a href="https://webmail.bool.rs" target="_blank">Webmail</a>
	      <a href="https://filestash.bool.rs" target="_blank">FTP</a>
              <NavLink activeClassName='selected' to='/bcdl'>BCDL</NavLink>
            </div>

            <hr/>

            <div className='boolAlert'>
                <NavLink activeClassName='selected' to='/boolalert'>bool alert</NavLink>
            </div>

            <hr id='smallHr'/>

            <div className='secondaryNavBar'>
              <div className='leftSideSecondary'>
                <NavLink activeClassName='selected' to={`/profile/${this.state.username}`}>Profile</NavLink>
                <NavLink activeClassName='selected' to='/editprofile'>[ Edit ]</NavLink>
              </div>

              <div className='rightSideSecondary'>
		<Link to='/' onClick={this.logout}>[ Logout ]</Link>
		{/*<NavLink activeClassName='selected' to='/inbox'>Inbox</NavLink>*/}
              </div>

            </div>

            <hr id='smallHr'/>
	    <div className='scroll-left'>
	      <p><b>MOTD:</b> go fuck yourself!</p>
	    </div>
	  </Container>
          <div className='break'/>
          <div className='mainBody'>
            <Route exact path='/'component={Feed}/>
            <Route exact path='/directory' component={Directory}/>
            <Route exact path='/bcdl' component={Bandcamp}/>
            <Route path='/profile/:user' component={Profile}/>
            <Route exact path='/guestbook' component={Guestbook}/>
            <Route exact path='/wiki' component={Wiki}/>
	    <Route exact path='/collages' component={Collages}/>
	    <Route exact path='/collages/view/:id' render={(props) => <ViewCollage {...props} currentUser={this.state.username}/>}/>
	      <Route exact path='/collages/edit/:id' render={(props) => <EditCollage {...props} currentUser={this.state.username}/>}/>
	    <Route exact path='/collages/add' component={NewCollage}/>
	    <Route path='/wiki/:page' component={WikiPage}/>
            <Route exact path='/editprofile' render={() => <EditProfile username={this.state.username}/>}/>
            <Route exact path='/inbox' component={Inbox}/>
            <Route exact path='/boolalert' component={BoolAlert}/>
          </div>
        </div>
      </div>
    );
  }
}
export default Main;
