import React, { Component } from 'react';
import { axios } from '../../utils/axios';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import './Wiki.css';

class Wiki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotData: false,
      data: []
    };
    this.renderLinks = this.renderLinks.bind(this);
  }

  componentDidMount() {
    console.log('mounted');
    axios.get('wiki')
      .then( res => {
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
        newState.data = res.data;
        this.setState(newState);
      })
      .catch( err => {
	console.log(err);
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
	newState.error = true;
        this.setState(newState);
      });
  }

  renderLinks() {
    const wikiLinks = [];
    this.state.data.forEach(link => {
      wikiLinks.push(
	<NavLink className='wikiLink' activeClassName='activeWikiLink' to={`/wiki/${link.file}`}>{link.title}</NavLink>
      );
    });
    return wikiLinks;
  }

  render() {
    return (
      <div className='wikiMain'>
	<hr className='directoryHr'></hr>
	<h1>Wiki</h1>
	<hr className='directoryHr'></hr>
	<div className='wikiBody'>
	  This wiki is meant to be a guide to what this stupid site has to offer, and how to use/get set up with all the services. Please check out the articles below before going elsewhere for help. Make sure to read the <b>What is bool.rs?</b> article to get affiliated with what the fuck this site actually is, and please read the <b>Rules</b>.
	</div>

	<hr className='directoryHr'></hr>
	<h2>Read These First</h2>
	<hr className='directoryHr'></hr>
	<div className='wikiLinkSection'>
	<NavLink className='wikiLink' activeClassName='activeWikiLink' to={`/wiki/about`}>What is bool.rs?</NavLink>
	<NavLink className='wikiLink' activeClassName='activeWikiLink' to={`/wiki/rules`}>Rules</NavLink>
	</div>

	<hr className='directoryHr'></hr>
	<h2>Index</h2>
	<hr className='directoryHr'></hr>
	<div className='wikiLinkSection'>
	{ this.state.gotData &&
	  this.renderLinks()
	}
	</div>
      </div>
  );
  }
}

export default Wiki;
