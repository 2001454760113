import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col } from '../../../node_modules/reactstrap/es/index.js';

import { axios } from '../../utils/axios';
import htmlDecode from '../../utils/htmlDecode';

import AddTracker from '../AddTracker.jsx';
import './EditProfile.css';

class TrackerHeader extends Component {
  render () {
    return (
      <div className='trackerMain'>
        <div className='trackerItem'>
          Tracker acronym
        </div>
        <div className='trackerItem'>
          Tracker URL
        </div>
        <div className='trackerItem'>
          Tracker username
        </div>
        <div className='trackerItem'>
          Have invites?
        </div>
        <div className='trackerItem'>
          Delete tracker
        </div>
      </div>
    );
  }
}
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      alertText: '',
      success: false,
      visible: false,
      gotProfile: false,
      deleteRedCreds: false,
      deleteRedAnnounceURL: false,
      trackers: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addTracker = this.addTracker.bind(this);
    this.renderTrackers = this.renderTrackers.bind(this);
    this.updateTrackerState = this.updateTrackerState.bind(this);
    this.deleteTracker = this.deleteTracker.bind(this);
    this.generateTrackerId = this.generateTrackerId.bind(this);
  }

  componentDidMount() {
    axios.get(`profile/${this.state.username}`)
      .then( res => {
        console.log(res.data);
        let newState = Object.assign({}, this.state);
        newState = Object.assign(newState, res.data);
        newState.bio = res.data.bio ? htmlDecode(res.data.bio) : '';
        newState.tag = res.data.tag ? htmlDecode(res.data.tag) : '';
        newState.location = res.data.location ? htmlDecode(res.data.location) : '';
        newState.trackers = {};
        res.data.trackers.forEach(tracker => {
          const trackerId = this.generateTrackerId();
          newState.trackers[trackerId] = {
            abbr: tracker.abbr,
            fullName: tracker.fullName,
            username: tracker.username,
            invites: tracker.invites,
            id: trackerId
          };
        });
        newState.gotProfile = true;
        this.setState(newState, () => {
          console.log(this.state);
        });
      }).catch( err => {
        console.log(err);
      });
  }

  onClick(e) {
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = !newState[e.target.name];
    this.setState(newState);
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState, () => {
      console.log(this.state);
    });
  }

  generateTrackerId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  addTracker() {
    const trackerId = this.generateTrackerId();
    let newState = Object.assign({}, this.state);
    newState.trackers[trackerId] = {
      abbr: '',
      username: '',
      fullName: '',
      invites: false,
      id: trackerId
    };
    this.setState(newState);
  }

  renderTrackers() {
    const numTrackers = Object.keys(this.state.trackers).length;
    if (numTrackers == 0) {
      return <div>no private trackers saved - add one!</div>;
    }
    let buffer = [];
    if (numTrackers > 0) {
      buffer.push(<TrackerHeader key='trackerHeader'/>);
    }
    Object.values(this.state.trackers).forEach(tracker => {
      buffer.push(<AddTracker tracker={tracker} onChange={this.updateTrackerState} onDelete={this.deleteTracker} key={tracker.id}/>);
    });
    return buffer;
  }

  updateTrackerState(trackerObj) {
    let newState = Object.assign({}, this.state);
    newState.trackers[trackerObj.id] = trackerObj;
    this.setState(newState);
  }

  deleteTracker(trackerObj) {
    let newState = Object.assign({}, this.state);
    delete newState.trackers[trackerObj.id];
    this.setState(newState);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    const dataObj = {};

    if ('bio' in this.state)
      dataObj.bio = this.state.bio;

    if ('redAnnounceURL' in this.state)
      dataObj.redAnnounceURL = this.state.redAnnounceURL;

    if ('redUsername' in this.state)
      dataObj.redUsername = this.state.redUsername;

    if ('redPassword' in this.state)
      dataObj.redPassword = this.state.redPassword;

    if ('location' in this.state)
      dataObj.location = this.state.location;

    if ('tag' in this.state)
      dataObj.tag = this.state.tag;

    if ('imageUrl' in this.state)
      dataObj.imageUrl = this.state.imageUrl;

    dataObj.deleteRedCreds = this.state.deleteRedCreds;
    dataObj.deleteRedAnnounceURL = this.state.deleteRedAnnounceURL;

    dataObj.trackers = [];
    var fail = false;
    var msg;
    Object.values(this.state.trackers).forEach(tracker => {
      if (!tracker.abbr) {
        fail = true;
        msg = 'Must enter an abbreviation for all trackers (e.g., RED)';
      } else if (!tracker.fullName) {
        fail = true;
        msg = 'Must enter a URL for all trackers (e.g., redacted.ch)';

      } else if (!tracker.username) {
        fail = true;
        msg = 'Must enter a username for all trackers (e.g., clang)';
      } else {
        dataObj.trackers.push({
          abbr: tracker.abbr,
          fullName: tracker.fullName,
          username: tracker.username,
          invites: tracker.invites
        });
      }
    });
    if (fail) {
      let newState = Object.assign({}, this.state);
      newState.visible = true;
      newState.alertText = msg;
      return this.setState(newState);
    } else {
      axios.post('/editprofile', dataObj)
        .then( res => {
	  // Update state, show success message
          let newState = Object.assign({}, this.state);
          newState.success = true;
          this.setState(newState);
        }).catch( err => {
          let newState = Object.assign({}, this.state);
          newState.visible = true;
          newState.alertText = err.response.data;
          this.setState(newState);
        });
    }
  }

  render() {
    if (!this.state.gotProfile) {
      return null;
    }
    if (!this.state.success) {
      return (

        <div>
          <h1><tt>edit your profile</tt> </h1>
          <br/>
          <hr className='editHr'/>
          <Alert color="dark" id='inviteAlert' isOpen={this.state.visible}>
            <tt>{this.state.alertText}</tt>
          </Alert>

          <div className="inviteForm">
            <Form className="form">
              <Col>

                <FormGroup>
                  <Label for='bio'><tt>bio</tt></Label>
                  <div>
                    <Input type="textarea" name="bio" id="textForm" placeholder='enter a bio here (max 400 chars).' defaultValue={this.state.bio} onChange={this.handleChange}/>
                  </div>
                </FormGroup>
              </Col>
              <hr className='editHr'/>
              <Col>

                <FormGroup>
                  <Label for='location'><tt>location</tt></Label>
                  <div>
                    <Input  name="location" id="dataForm" defaultValue={this.state.location} placeholder="be as vague as you want" onChange={this.handleChange}/>
                  </div>
                </FormGroup>

              </Col>
              <hr className='editHr'/>
              <br/>

              <Col>
                <FormGroup>
                  <Label for='tag'><tt>tagline</tt></Label>
                  <div><i>(stupid text that appears near your username)</i></div>
                  <div>
                    <Input name="tag" id="dataForm" defaultValue={this.state.tag} placeholder="(max 20 chars)" onChange={this.handleChange}/>
                  </div>
                </FormGroup>
              </Col>
              <br/>
              <hr className='editHr'/>

              {!this.state.deleteRedCreds &&
               <div>
                 <Col>
                   <FormGroup>
                     <Label for='redUsername'><tt>RED username</tt></Label>
                     <div>
                       <Input type="username" name="redUsername" id="dataForm" placeholder="RED username" onChange={this.handleChange}/>
                     </div>
                   </FormGroup>

                 </Col>

                 <br/>

                 <Col>
                   <FormGroup>
                     <Label for='redPassword'><tt>RED password</tt></Label>
                     <div>
                       <Input type="password" name="redPassword" className='pwBox' placeholder="RED password" onChange={this.handleChange}/>
                     </div>
                   </FormGroup>
                 </Col>
               </div>
              }

              {this.state.hasRedCreds &&
	       <FormGroup check>
                 <Label check>
                   <Input name="deleteRedCreds" type="checkbox" onClick={this.onClick}/>{' '}
                   Delete saved RED credentials?
                 </Label>
               </FormGroup>
              }

              <br/>

              {!this.state.deleteRedAnnounceURL &&
               <div>

                 <Col>
                   <FormGroup>
                     <Label for='redAnnounceURL'><tt>RED Announce URL</tt></Label>
                     <div>
                       <Input type="text" name="redAnnounceURL" className='pwBox' placeholder="RED Announce URL" onChange={this.handleChange}/>
                     </div>
                   </FormGroup>
                 </Col>
               </div>
              }

              {this.state.hasRedAnnounceURL &&
	       <FormGroup check>
                 <Label check>
                   <Input name="deleteRedAnnounceURL" type="checkbox" onClick={this.onClick}/>{' '}
                   Delete saved RED Announce URL?
                 </Label>
               </FormGroup>
              }
              <br/>
              <hr className='editHr'/>

              <Col>

                <FormGroup>
                  <Label for='imageUrl'><tt>profile image url</tt></Label>
                  <div>
                    <Input name="imageUrl" id="dataForm" defaultValue={this.state.imageUrl} placeholder="cool.com/myimage.jpg" onChange={this.handleChange}/>
                  </div>
                </FormGroup>

              </Col>
              <br/>
              <hr className='editHr'/>
            </Form>

            <FormGroup>
              <Button id="submit" onClick={this.addTracker}><tt>add private tracker</tt></Button>
            </FormGroup>
            <br/>
            {this.renderTrackers()}
            <br/>
            <hr className='editHr'/>
            <Form>
              <FormGroup>
                <Button id="submit" onClick={this.handleSubmit}><tt>submit</tt></Button>
              </FormGroup>
            </Form>

          </div>
        </div>
      );
    } else {
      return (
	<div className='addCollageSuccess'>
	  <div>
            Profile successfully updated.
          </div>
	</div>
      );
    }
  }
}

export default EditProfile;
