import React, { Component } from 'react';
import Logo from '../Logo';
import Background from '../Background';
import MainBackground from '../MainBackground';
import Login from '../Login';
import Invite from '../Invite';
import ForgotUser from '../ForgotUsername';
import ForgotPass from '../ForgotPassword';
import Banner from '../Banner';
import { Form, FormGroup, Label, Input, Button, Container } from '../../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Splash from '../Splash';
import Main from '../Main';

import loggedIn from '../../utils/auth';

import './Home.css';
import history from '../../utils/history';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      gotStatus: false,
      redirect: false
    };

    this.onRedirect = this.onRedirect.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    loggedIn((auth, username) => {
      this.setState({
        loggedIn: auth,
        gotStatus: true,
        username: username
      });
    });
  }

  logOut() {
    this.setState({loggedIn: false});
  }

  onRedirect(value) {
    let newState = Object.assign({}, this.state);
    this.state.redirect = value;
    this.setState(newState);
  }
  render() {
    if (!this.state.gotStatus) {
      return null;
    }

    if (this.state.loggedIn) {
      return <Main username={this.state.username} logOut={this.logOut}/>;
    }

    return <Splash/>;

  }
}
export default Home;
