import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import './Comment.css';

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.comment.username,
      text: props.comment.text,
      date: props.comment.date,
      imageUrl: props.comment.imageUrl
    };
  }

  render() {
    return (
      <div className='guestComment'>
      {this.state.imageUrl &&
       <img className='guestImage' src={this.state.imageUrl}/>
      }
        <div className='textArea'>
          <div className='textUserBar'>
            by <Link to={`/profile/${this.state.username}`} className='commentUsername'>{this.state.username}</Link>, on {this.state.date}
          </div>
          <hr className='commentHr'/>
          <div className='commentText' dangerouslySetInnerHTML={{__html: this.state.text}}>
          </div>
        </div>
      </div>
    );
  }
}

export default Comment;
