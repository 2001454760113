import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import './App.css';
import Home from './pages/Home';

function App () {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home}/>
      </Switch>
    </Router>
  );
}

export default App;
