import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col } from '../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link} from "react-router-dom";

import { axios } from '../utils/axios';

import './Invite.css';

class Invite extends Component {

  constructor(props) {
    super(props);
    this.state = {email: '',
                  user: '',
                  pw1: '',
                  pw2: '',
                  text: '',
                  visible: false,
                  alertText:'',
		  success: false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    axios.post('/register', {
      emailAddress: this.state.email,
      username: this.state.user,
      password: this.state.pw1,
      password2: this.state.pw2,
      inviteRequestComment: this.state.text})
      .then( res => {
	// Update state, show success message
	let newState = Object.assign({}, this.state);
	newState.success = true;
	this.setState(newState);
      }).catch( err => {
        let newState = Object.assign({}, this.state);
        newState.alertText = err.response.data;
	console.log(err.response.data);
        newState.visible = true;
        this.setState(newState);
  });

  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value
    this.setState(newState);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    if (!this.state.success) {
    return (
        <Container>
        <Alert color="dark" id='inviteAlert' isOpen={this.state.visible}>
        <tt>{this.state.alertText}</tt>
      </Alert>
        <div className="inviteForm">
          <Form className="form">
            <Col>
              <FormGroup>
                <Label for='email'><tt>email</tt></Label>
                <div>
                  <Input type="email" name="email" id="dataForm" placeholder="booler@bool.rs" onChange={this.handleChange}/>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for='user'><tt>username</tt></Label>
                <div>
                  <Input type="username" name="user" id="dataForm" placeholder="xXx_big_booler_xXx" onChange={this.handleChange}/>
                </div>
              </FormGroup>
        </Col>
        <br/>
        <br/>
        <Col>

        <FormGroup>
                        <Label for='pw1'><tt>password</tt></Label>
                <div>
        <Input type="password" name="pw1" className="pwBox" placeholder="pass" onChange={this.handleChange}/>
        </div>
        </FormGroup>
        </Col>
                            <Col>
        <FormGroup>
                                <Label for='pw2'><tt>repeat password</tt></Label>
                <div>
        <Input type="password" name="pw2" className="pwBox" placeholder="repeat pass" onChange={this.handleChange}/>
        </div>
        </FormGroup>
        </Col>
        <br/>
        <br/>
            <Col>
              <FormGroup>
                <Label for='text'><tt>how do i know you?</tt></Label>
                <div>
                  <Input type="textarea" name="text" id="textForm" placeholder='please tell me :)'onChange={this.handleChange}/>
                </div>
              </FormGroup>
            </Col>
          </Form>
          <Form>
            <FormGroup>
              <Button id="submit" onClick={this.handleSubmit}><tt>submit</tt></Button>
            </FormGroup>
          </Form>

        </div>
      </Container>
    );
    } else {
      return (
	  <div className="pwForm">
<tt>
	 nice! an invite request has been sent to the <i>master booler</i>. you should receive an email soonish with the results.
</tt>
	  </div>
      );
    }
  }
}

export default Invite;
