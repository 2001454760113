import React, { Component } from 'react';

import { axios } from '../../utils/axios';

import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import './Directory.css';

class Directory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotUsers: false,
      error: false
    };

    this.processUsers = this.processUsers.bind(this);
    this.renderUsers = this.renderUsers.bind(this);
  }

  processUsers(userList) {
    const adminUsers = [];
    const memberUsers = [];
    userList.forEach(user => {
      if (user.admin) {
        adminUsers.push(user.username);
      } else {
        memberUsers.push(user.username);
      }
    });
    adminUsers.sort();
    memberUsers.sort();
    return {
      adminUsers: adminUsers,
      memberUsers: memberUsers
    };
  }

  renderUsers() {
    const result = [];

    console.log(this.state.users);
    if (this.state.users.adminUsers.length != 0) {
      result.push(<h2 id='adminHeader'>admins</h2>);
      this.state.users.adminUsers.forEach( user => {
        result.push(<Link to={`/profile/${user}`} className='userLink'>{user}</Link>);
      });
    }
    if (this.state.users.memberUsers.length != 0) {
      result.push(<h2>users</h2>);
      this.state.users.memberUsers.forEach( user => {
        result.push(<Link to={`/profile/${user}`} className='userLink'>{user}</Link>);
      });
    }
    return result;
  }

  componentDidMount() {
    axios.get('listusers')
      .then( res => {
        let newState = Object.assign({}, this.state);
        newState.users = this.processUsers(res.data.users);
        newState.gotUsers = true;
        this.setState(newState);
      })
      .catch( err => {
      });
  }

  render() {
    if (!this.state.gotUsers && !this.state.error) {
      return null;
    } else if (this.state.error && !this.state.gotUsers) {
      return <Redirect to="/"/>;
    } else {
      return (
        <div id='directory'>
          <hr className='directoryHr'/>
          <h1>Directory</h1>
          <hr className='directoryHr'/>
          <div id='userList'>
            { this.renderUsers() }
          </div>
          <hr className='directoryHr'/>
      </div>
      );
    }
  }
}

export default Directory;
