import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

import { axios } from '../../utils/axios';

import './Rules.css';

class Rule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: props.number,
      text: props.text
    };
  }

  render() {

    return (
      <div>
	<div id='number'>{this.state.number}</div> {this.state.text}
	<br/>
	<br/>
      </div>
    );
  }
}

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotData: false,
      data: []
    };

    this.renderRules = this.renderRules.bind(this);
  }

  componentDidMount() {
    axios.get('rules')
      .then( res => {
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
	console.log(res.data);
        newState.data = res.data.rules;
        this.setState(newState);
      })
      .catch( err => {
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
	console.log(err);
	newState.error = true;
        this.setState(newState);
      });
  }

  renderRules() {
    const rules = [];
    this.state.data.forEach( rule => {
      rules.push(<Rule number={rule.number} text={rule.text}/>);
    });
    return rules;
  }

  render() {
    if (this.state.gotData) {
      return (
	<div className=''>
	  <hr className='directoryHr'/>
          <h1>Rules</h1>
          <hr className='directoryHr'/>
	  <div className='rulesBody'>
	    {this.renderRules()}
	  </div>
	</div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Rules;
