import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col } from '../../node_modules/reactstrap/es/index.js';

import './AddTracker.css';

class AddTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abbr: props.tracker.abbr,
      fullName: props.tracker.fullName,
      username: props.tracker.username,
      invites: props.tracker.invites,
      id: props.tracker.id
    };
    console.log(this.state);
    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.deleteTracker = this.deleteTracker.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState, () => {
      this.props.onChange(this.state);
    });
  }

  onClick(e) {
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = !newState[e.target.name];
    this.setState(newState, () => {
      this.props.onChange(this.state);
    });
  }

  deleteTracker(e) {
    e.preventDefault();
    this.props.onDelete(this.state);
  }

  render() {
    return (
      <div className='trackerMain'>
        <div className='trackerItem'>
          <Form className='form'>
            <div>
              <Input className='input' name="abbr" id="dataForm" className="trackerInput" placeholder='RED' defaultValue={this.state.abbr} onChange={this.handleChange}/>
            </div>
          </Form>
        </div>
        <div className='trackerItem'>
          <Form className='form'>
            <div>
              <Input className='input' name="fullName" id="dataForm" className="trackerInput" placeholder='redacted.ch' defaultValue={this.state.fullName} onChange={this.handleChange}/>
            </div>
          </Form>
        </div>
        <div className='trackerItem'>
          <Form className='form'>
            <div>
              <Input className='input' name="username" id="dataForm" className="trackerInput" placeholder='coolguy69' defaultValue={this.state.username} onChange={this.handleChange}/>
            </div>
          </Form>
        </div>
        <div className='trackerItem'>
          <Form>
            <Input className='input' name="invites" type="checkbox" defaultValue={this.state.invites} defaultChecked={this.state.invites} onClick={this.onClick}/>{' '}
          </Form>
        </div>
        <div className='trackerItem'>
          <Form>
            <Input className='input' name="delete" type="checkbox" onClick={this.deleteTracker}/>{' '}
          </Form>
        </div>
      </div>
    );
  }
}

export default AddTracker;
