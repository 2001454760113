import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col, UncontrolledCollapse, Card, CardBody } from '../../node_modules/reactstrap/es/index.js';

import { axios } from '../utils/axios';

import './BandcampAlbum.css';

class BandcampAlbum extends Component {
  constructor(props) {
    super(props);
    const tralbum_description = this.constructAlbumDescription(
      props.album.description || '',
      props.album.tracks,
      props.album.tralbum_uri,
      props.album.artist,
      props.album.tralbum_title
    );

    this.state = {
      tralbum_uri: props.album.tralbum_uri,
      fixed_tralbum_title: props.album.tralbum_title,
      fixed_artist: props.album.artist,
      fixed_art_uri: props.album.art_uri,
      artist: props.album.artist,
      art_uri: props.album.art_uri,
      tralbum_title: props.album.tralbum_title,
      description: tralbum_description,
      release_date: props.album.release_date,
      initial_year: props.album.release_year,
      edition_year: props.album.release_year,
      track_info: props.album.tracks,
      total_duration: props.album.total_duration,
      tags: props.album.tralbum_tags,
      release_type: 'Album',
      collapse: true,
      visible: false,
      download_uri: props.album.download_uri,
      msg: '',
      retry: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.renderCollapseText = this.renderCollapseText.bind(this);
    this.renderAlbumInfo = this.renderAlbumInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeReleaseValue = this.changeReleaseValue.bind(this);
    this.getREDSearchLink = this.getREDSearchLink.bind(this);
    this.getREDAlbumSearchLink = this.getREDAlbumSearchLink.bind(this);
    this.constructAlbumDescription = this.constructAlbumDescription.bind(this);
  }

  resetState(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState.visible = false;
    newState.retry = false;
    this.setState(newState);
  }

  handleSubmit(e) {
    e.preventDefault();
    const dataObj = {
      artist: this.state.artist,
      art_uri: this.state.art_uri,
      tralbum_uri: this.state.tralbum_uri,
      tralbum_title: this.state.tralbum_title,
      description: this.state.description,
      initial_year: this.state.initial_year,
      edition_year: this.state.edition_year,
      tags: this.state.tags,
      release_type: this.state.release_type,
      download_uri: this.state.download_uri
    };
    let newState = Object.assign({}, this.state);
    newState.visible = true;
    newState.retry = false;
    newState.msg = 'we\'re uploading now... please be patient, this shit takes a while.';
    this.setState(newState, () => {
      axios.post('/uploadalbum', dataObj)
        .then( res => {
          let newState = Object.assign({}, this.state);
          newState.msg = res.data;
          this.setState(newState);
        }).catch( err => {
          let newState = Object.assign({}, this.state);
          newState.msg = err.response.data;
          newState.retry = true;
          this.setState(newState);
        });
    });

  }

  constructAlbumDescription(description, tracks, url, artist, tralbum_title) {

    let trackStr = `[size=4][b][artist]${artist}[/artist] - ${tralbum_title}[/b][/size]\n\n[size=3][b]Tracklist[/b][/size]\n\n`;

    tracks.forEach(track => {
      trackStr = trackStr.concat(`[b]${track.num}[/b] - ${track.title} [i]${track.fmt_duration}[/i]\n`);
    });
    trackStr = trackStr.concat('\n');

    if (description != '') {
      trackStr = trackStr.concat(`${description}\n\n`);
    }

    trackStr = trackStr.concat(`More information: ${url}`);
    return trackStr;
  }

  getREDSearchLink() {
    const artistString = this.state.fixed_artist.replace(' ', '+');
    return `https://redacted.ch/torrents.php?artistname=${artistString}`;
  }

  getREDAlbumSearchLink() {
    const albumString = this.state.fixed_tralbum_title.replace(' ', '+');
    return `https://redacted.ch/torrents.php?searchstr=${albumString}`;
  }

  changeReleaseValue(e) {
    let newState = Object.assign({}, this.state);
    newState.release_type = e.target.value;
    this.setState(newState);
  }

  toggleCollapse(e) {
    let newState = Object.assign({}, this.state);
    newState.collapse = !this.state.collapse;
    this.setState(newState);
  }

  renderCollapseText() {
    if (this.state.collapse) {
      return (
        <div className='collapseButton'>
          <h2>↓↓↓↓↓↓↓</h2>
        </div>);
    } else {
      return (
        <div className='collapseButton'>
          <h2>↑↑↑↑↑↑↑</h2>
        </div>);
    }
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  renderTracks() {
    const result = [];
    this.state.track_info.forEach(track => {
      result.push(
        <div className='trackMetaRow'>
            <h3>{track.num}</h3>&nbsp;-&nbsp;<h4>{track.title}</h4>&nbsp;-&nbsp;{track.fmt_duration}
        </div>
      );
    });
    return result;
  }

  renderAlbumInfo() {
    if (!this.state.collapse) {
      if (this.state.visible) {
        return (
            <div className='bandcampAlbumInfo'>
              <hr className='bandcampHr'/>
              <div className='infoRowElement'>
                <Alert color="dark" id='bcdlAlert'>
                  {this.state.msg}
                </Alert>
                 
              </div>
              {this.state.retry &&
               <div className='infoRowElement'>
                 <Button className='REDSearchButton' onClick={this.resetState}>
                 go back
                 </Button>
               </div>
              }
            </div>
        );
      }
      return (
        <div className='bandcampAlbumInfo'>
          <hr className='bandcampHr'/>
          <div className='infoRowElement'>
            <div>
              <a href={this.getREDSearchLink()} target="_blank" className='albumImgWrapper'>
                <Button className='REDSearchButton'>
                  Search RED for this artist
                </Button>
              </a>
            </div>
          </div>

          <div className='infoRowElement'>
            <div>
              <a href={this.getREDAlbumSearchLink()} target="_blank" className='albumImgWrapper'>
                <Button className='REDSearchButton'>
                  Search RED for this album
                </Button>
              </a>
            </div>
        </div>

          <div className='artistRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Artist:</h3>
            </div>
            <div>
              <Input type="text" name="artist" className='albumInfoTextForm' defaultValue={this.state.artist} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='titleRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Album Title:</h3>
            </div>
            <div>
              <Input type="text" name="tralbum_title" className='albumInfoTextForm' defaultValue={this.state.tralbum_title} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='descriptionRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Album Description:</h3>
            </div>
            <div>
              <Input type="textarea" name="description" className='albumTextForm' defaultValue={this.state.description} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='releaseTypeRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Release Type:</h3>
            </div>
            <Input type="select" className='releaseTypeSelect' name="select" onChange={this.changeReleaseValue} defaultValue={this.state.release_type}>
              <option value={'Album'}>Album</option>
              <option value={'Soundtrack'}>Soundtrack</option>
              <option value={'EP'}>EP</option>
              <option value={'Anthology'}>Anthology</option>
              <option value={'Single'}>Single</option>
              <option value={'Live Album'}>Live Album</option>
              <option value={'Compilation'}>Compilation</option>
              <option value={'Remix'}>Remix</option>
              <option value={'Bootleg'}>Bootleg</option>
              <option value={'Interview'}>Interview</option>
              <option value={'Mixtape'}>Mixtape</option>
              <option value={'Demo'}>Demo</option>
              <option value={'Concert Recording'}>Concert Recording</option>
              <option value={'DJ Mix'}>DJ Mix</option>
              <option value={'Unknown'}>Unknown</option>
            </Input>
          </div>

          <div className='initialYearRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Initial Year:</h3>
            </div>
            <div>
              <Input type="text" name="initial_year" className='albumInfoTextForm' defaultValue={this.state.initial_year} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='editionYearRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Edition Year:</h3>
            </div>
            <div>
              <Input type="text" name="edition_year" className='albumInfoTextForm' defaultValue={this.state.edition_year} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='tagRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Tags:</h3>
            </div>
            <div>
              <Input type="text" name="tags" className='albumInfoTagForm' defaultValue={this.state.tags} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='imageLinkRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Image:</h3>
            </div>
            <div>
              <Input type="text" name="tralbum_uri" className='albumInfoTextForm' defaultValue={this.state.art_uri} onChange={this.handleChange}/>
            </div>
          </div>

          <div className='totalDurationRow infoRowElement'>
            <div className='infoRowElementLabel'>
              <h3>Total Duration:</h3>
            </div>
            <div>
              {this.state.total_duration}
            </div>
          </div>

          <div className='trackRow infoColumnElement'>
            <h3>Track Listing</h3>
            <div>
              {this.renderTracks()}
            </div>
          </div>
          <div className='infoRowElement'>
            <Button className='bandcampSubmitButton' onClick={this.handleSubmit}>
              submit
            </Button>
          </div>
        </div>);
    }
  }

  render() {
    return (
      <div className='bandcampAlbum'>
        <div className='bandcampAlbumHeader'>
          <a href={this.state.tralbum_uri} target="_blank" className='albumImgWrapper'>
            <img className='guestImage' src={this.state.art_uri}/>
          </a>
          <div className='bandcampExpandColumn'>
            <div>
              <h2>{this.state.fixed_tralbum_title}</h2>
            </div>
            <div>
              by
            </div>
            <div>
              <h3><i>{this.state.fixed_artist}</i></h3>
            </div>
            <Button className='bandcampExpandButton' onClick={this.toggleCollapse}>
              {this.renderCollapseText()}
            </Button>
          </div>
        </div>
        {this.renderAlbumInfo()}
      </div>
    );
  }
}

export default BandcampAlbum;
