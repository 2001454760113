import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Container, Col, Alert } from '../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { axios } from '../utils/axios';

import './pages/Home.css';

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      msg: '',
      visible: false,
      success: false
    };

    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    axios.post('/forgot-password', {
      username: this.state.username})
      .then(res => {
	let newState = Object.assign({}, this.state);
	newState.success = true;
	this.setState(newState);
      }).catch(err => {
	console.log(err.response.data);
	let newState = Object.assign({}, this.state);
	newState.msg = err.response.data;
	newState.visible = true;
	this.setState(newState);
      });
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  renderBody() {
    if (!this.state.success) {
      return (
        <div className="pwForm">
          <Container>
	    <Alert color="dark" className="alert" isOpen={this.state.visible}>
              <tt>{this.state.msg}</tt>
	    </Alert>
            <Col>
              <div id="loginMsg">
                <tt>great job! enter your username and we'll send you a link to reset your password. or, if you also forgot your username, <Link to='/forgotlogin' id='forgotEmailLink'>click here</Link>.</tt>
              </div>
            </Col>
            <Col>
              <Form onSubmit={this.onClick}>
                <FormGroup>
                  <Input type="username" name="username" id="dataForm" placeholder="cooldude112233" onChange={this.handleChange}/>
                </FormGroup>
              </Form>
            </Col>
            <Col>
              <Form>
                <FormGroup>
                  <Button id="submit" onClick={this.onClick}><tt>submit</tt></Button>
                </FormGroup>
              </Form>
            </Col>
          </Container>
        </div>
      );
    } else {
      return (
	<div className="pwForm">
          <tt>
	    cool. we just sent you an email with instructions to reset your password.
          </tt>
	</div>
      );
    }
  }
  render() {
    return (
      <div>
        {this.renderBody()}
      </div>
    );
  }
}

export default ForgotPass;
