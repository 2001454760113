import React, { Component } from 'react';
import { axios } from '../../utils/axios';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col, Modal, ModalHeader, ModalBody, ModalFooter } from '../../../node_modules/reactstrap/es/index.js';
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';

import './NewCollage.css';


class Album extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artist: props.artist || '',
      album: props.album || '',
      note: props.note || '',
      image: props.image || '',
      row: props.row,
      column: props.column,
      active: props.row === 0 && props.column === 0
    };
    this.updateArtist = this.updateArtist.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.updateAlbum = this.updateAlbum.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.getActiveStyle = this.getActiveStyle.bind(this);
  }

  updateImage(image) {
    let newState = Object.assign({}, this.state);
    newState.image = image;
    this.setState(newState);
  }

  updateNote(note) {
    let newState = Object.assign({}, this.state);
    newState.note = note;
    this.setState(newState);
  }

  updateArtist(artist) {
    let newState = Object.assign({}, this.state);
    newState.artist = artist;
    this.setState(newState);
  }

  updateAlbum(album) {
    let newState = Object.assign({}, this.state);
    newState.album = album;
    this.setState(newState);
  }

  toggleActive(cb) {
    let newState = Object.assign({}, this.state);
    newState.active = !this.state.active;
    this.setState(newState, () => {
      if (cb !== null) {
	cb();
      }
    });
  }

  getActiveStyle() {
    if (!this.state.active) {
      return {'background-image': `url(${this.state.image})`};
    }
    return {'border-color': 'white', 'background-image': `url(${this.state.image})`};
  }
  render() {
    return (
      <div className='collageAlbum' style={this.getActiveStyle()} onClick={() => this.props.makeActive(this.state, null)}>
      </div>
    );
  }
}

class EditCollage extends Component {

  constructor(props) {
    super(props);

    const albums = [];
    const refs = [];

    this.makeActive = this.makeActive.bind(this);


    this.state = {
      currentUser: props.currentUser,
      id: props.match.params.id,
      title: '',
      description: '',
      type: 'Personal',
      albums,
      refs,
      albumArtist: '',
      albumTitle: '',
      albumNote: '',
      albumImage: '',
      gotArt: false,
      artLinks: [],
      alertText: '',
      visible: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderCollage = this.renderCollage.bind(this);
    this.renderCollageRow = this.renderCollageRow.bind(this);
    this.updateArtist = this.updateArtist.bind(this);
    this.updateAlbum = this.updateAlbum.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.findArt = this.findArt.bind(this);
    this.renderArtLinks = this.renderArtLinks.bind(this);
    this.renderArtRow = this.renderArtRow.bind(this);
    this.setAlbumArt = this.setAlbumArt.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.submitCollage = this.submitCollage.bind(this);
    this.getCollageData = this.getCollageData.bind(this);
    this.unserializeAlbums = this.unserializeAlbums.bind(this);
    this.deleteCollage = this.deleteCollage.bind(this);
  }

  deleteCollage(e) {
    e.preventDefault();
    axios.post('/deletecollage', {id: this.state.id})
      .then( res => {
	let newState = Object.assign({}, this.state);
	newState.deleted = true;
	this.setState(newState);
      }).catch( err => {
	let newState = Object.assign({}, this.state);
	newState.visible = true;
	newState.alertText = err.response.data;
	this.setState(newState);
      });
  }

  componentDidMount() {
    axios.post('/getcollage', {id: this.state.id}).
      then( res => {
	if (res.data.user !== this.state.currentUser) {
	  let newState = Object.assign({}, this.state);
	  newState.invalidUser = true;
	  this.setState(newState);
	} else {
	  let newState = Object.assign({}, this.state);
	  newState.title = res.data.title;
	  newState.description = res.data.description;
	  newState.type = res.data.type;
	  newState.columns = res.data.columns;
	  newState.rows = res.data.rows;
	  newState.gotData = true;
	  const albumData = this.unserializeAlbums(res.data.collageData, res.data.rows, res.data.columns);
	  newState.refs = albumData.refs;
	  newState.activeRef = albumData.refs[0][0];
	  newState.albums = albumData.albums;
	  newState.albumArtist = res.data.collageData[0].artist;
	  newState.albumTitle = res.data.collageData[0].album;
	  newState.albumNote = res.data.collageData[0].note;
	  newState.albumImage = res.data.collageData[0].image;
	  newState.id = res.data.count;
	  this.setState(newState, () => {
	    console.log(this.state);
	  });
	}
      }).catch( err => {
	let newState = Object.assign({}, this.state);
	newState.invalidUser = true;
	this.setState(newState);
      });
  }

  unserializeAlbums(collageData, rows, columns) {
    const albums = [];
    const refs = [];
    for (let i = 0; i < rows; i++) {
      refs.push([]);
      albums.push([]);
      for (let j = 0; j < columns; j++) {
	const album = collageData[i*columns + j];
	let ref = React.createRef();
	albums[i].push(<Album ref={ref} row={i} column={j} artist={album.artist} album={album.album} note={album.note} image={album.image} makeActive={this.makeActive}/>);
	refs[i].push(ref);
      }
    }
    return {albums, refs};
  }

  submitCollage(e) {
    e.preventDefault();
    if (this.state.title == '') {
      let newState = Object.assign({}, this.state);
      newState.visible = true;
      newState.alertText = 'Please enter a title for the collage.';
      this.setState(newState);
    } else {
      const dataObj = {
	collageData: this.getCollageData(),
	title: this.state.title,
	type: this.state.type,
	description: this.state.description,
	columns: this.state.columns,
	rows: this.state.rows,
	id: this.state.id
      };

      axios.post('/editcollage', dataObj)
	.then( res => {
	  let newState = Object.assign({}, this.state);
	  newState.success = true;
	  this.setState(newState);
	}).catch( err => {
	  let newState = Object.assign({}, this.state);
	  newState.visible = true;
	  newState.alertText = err.response.data;
	  this.setState(newState);
	});
    }
  }

  getCollageData() {
    const albumData = [];
    for (let i = 0; i < this.state.rows; i++) {
      for (let j = 0; j < this.state.columns; j++) {
	let currentRef = this.state.refs[i][j].current.state;
	albumData.push({
	  artist: currentRef.artist,
	  album: currentRef.album,
	  note: currentRef.note,
	  image: currentRef.image
	});
      }
    }
    return albumData;
  }

  makeActive(state, cb) {
    console.log(state);
    this.state.activeRef.current.toggleActive(() => {
      let newState = Object.assign({}, this.state);
      newState.activeRef = this.state.refs[state.row][state.column];
      newState.albumArtist = state.artist;
      newState.albumTitle = state.album;
      newState.albumNote = state.note;
      newState.albumImage = state.albumImage;
      newState.gotArt = false;
      newState.artLinks = [];
      this.setState(newState, () => {
	this.state.activeRef.current.toggleActive(cb);
      });
    });

  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  setAlbumArt(e) {
    this.state.activeRef.current.updateImage(e.target.src);
  }

  renderArtRow(artLinks) {
    const artRow = [];
    artLinks.forEach(link => {
      artRow.push(
	<img className='artSectionImage' src={link} onClick={this.setAlbumArt}>

	</img>
      );
    });
    return (
      <div className='artSectionRow'>
	{artRow}
      </div>
    );

  }

  renderArtLinks() {
    return (
      <div className='artSection'>
	{this.renderArtRow(this.state.artLinks.slice(0,8))}
	{this.renderArtRow(this.state.artLinks.slice(8,16))}
      </div>

    );
  }

  renderCollageRow(row) {
    return (
      <div className='collageRow'>
	{row}
      </div>
    );
  }

  renderCollage() {
    const rows = [];
    this.state.albums.forEach(row => {
      rows.push(this.renderCollageRow(row));
    });
    return (
      <div className='collageMain'>
	{rows}
      </div>
    );
  }

  updateArtist(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState.albumArtist = e.target.value;
    this.state.activeRef.current.updateArtist(e.target.value);
    this.setState(newState);
  }

  updateNote(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState.albumNote = e.target.value;
    this.state.activeRef.current.updateNote(e.target.value);
    this.setState(newState);
  }

  updateAlbum(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState.albumTitle = e.target.value;
    this.state.activeRef.current.updateAlbum(e.target.value);
    this.setState(newState);
  }

  findArt(e) {
    e.preventDefault();
    if (this.state.albumArtist == '' && this.state.albumTitle == '') {
      let newState = Object.assign({}, this.state);
      newState.alertText = 'You must enter an album title or artist before searching for art.';
      newState.visible = true;
      this.setState(newState);
    } else {
      axios.post('/collage/getimages', {query: `${this.state.albumArtist} ${this.state.albumTitle}`})
	.then( res => {
	  let newState = Object.assign({}, this.state);
	  newState.visible = false;
	  newState.gotArt = true;
	  newState.artLinks = res.data;
	  this.setState(newState);
	}).catch( err => {
	});
    }
  }

  onDropdownChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState.type = e.target.value;
    this.setState(newState);
  }

  updateSize(rows, columns) {
    // If rows/columns hasn't changed, we shouldn't do anything.
    if (rows == this.state.rows && columns == this.state.columns) {
      return;
    }
    // Similarly, if the new row/col number differs by more than one, something's wrong.
    // There's a race condition here, so we need this check.
    if (Math.abs(rows - this.state.rows) > 1 || Math.abs(columns - this.state.columns) > 1) {
      return;
    }

    // Always set the active album to 0,0 before doing anything else.
    this.state.refs[0][0].current.props.makeActive(this.state.refs[0][0].current.state, () => {
      let newState = Object.assign({}, this.state);
      newState.rows = rows;
      newState.columns = columns;

      if (rows < this.state.rows) {
	// Deleting one row
	newState.albums = this.state.albums.slice(0, this.state.albums.length-1);
	newState.refs = this.state.refs.slice(0, this.state.albums.length-1);
      } else if (rows > this.state.rows) {
	// Adding one row
	const newAlbums = [];
	const newRefs = [];
	for (let j = 0; j < this.state.columns; j++) {
	  let ref = React.createRef();
	  newAlbums.push(<Album ref={ref} row={rows-1} column={j} makeActive={this.makeActive}/>);
	  newRefs.push(ref);
	}
	newState.refs = this.state.refs.concat([newRefs]);
	newState.albums = this.state.albums.concat([newAlbums]);
      } else if (columns < this.state.columns) {
	// Deleting one column
	const newAlbums = [];
	const newRefs = [];
	for (let i = 0; i < this.state.rows; i++) {
	  newAlbums.push(this.state.albums[i].slice(0, columns));
	  newRefs.push(this.state.refs[i].slice(0, columns));
	}
	newState.refs = newRefs;
	newState.albums = newAlbums;
      } else if (columns > this.state.columns) {
	// Adding one column
	const newAlbums = [];
	const newRefs = [];
	for (let i = 0; i < this.state.rows; i++) {
	  let ref = React.createRef();
	  newAlbums.push(this.state.albums[i].concat([<Album ref={ref} row={i} column={columns-1} makeActive={this.makeActive}/>]));
	  newRefs.push(this.state.refs[i].concat([ref]));
	}
	newState.refs = newRefs;
	newState.albums = newAlbums;
      }
      this.setState(newState);
    });
  }

  render() {
    if (this.state.success) {
      return (
	<div className='addCollageSuccess'>
	  <div>
	    Your collage has been updated. You can view it <NavLink activeClassName='selected' to={`/collages/view/${this.state.id}`}>here</NavLink>
	  </div>
	</div>
      );
    }
    if (this.state.deleted) {
      return (
	<div className='addCollageSuccess'>
	  <div>
	    Your collage has been deleted succesfully.
	  </div>
	</div>
      );
    }
    if (this.state.gotData) {
      return (
	<div className='addCollageMain'>
	  <hr className='directoryHr'></hr>
	  <h1>Edit Collage</h1>

	  <hr className='directoryHr'></hr>
	  <Alert color="dark" id='commentAlert' isOpen={this.state.visible}>
            <tt>{this.state.alertText}</tt>
          </Alert>
	  <div className='collageInfoSection'>

	    <div className='collageInfoCol'>
              <Form>
		<Label classfor='title'><tt className='collageLabel'>Collage Title</tt></Label>
		<div>
		  <Input type="text" name="title" className='pwBox'  placeholder='Collage Title' defaultValue={this.state.title} onChange={this.handleChange}/>
		</div>
              </Form>

	      <FormGroup>
		<Label for="type">Collage Type</Label>
		<div>
		  <Input className='collageDropDown' type="select" name="type" id="type">
		    <option onClick={this.onDropdownChange}>Personal</option>
		    <option onClick={this.onDropdownChange}>Artist</option>
		    <option onClick={this.onDropdownChange}>Theme</option>
		    <option onClick={this.onDropdownChange}>Genre Introduction</option>
		    <option onClick={this.onDropdownChange}>Discography</option>
		    <option onClick={this.onDropdownChange}>Label</option>
		  </Input>
		</div>
	      </FormGroup>

	      <Form>
		<Label for='description'><tt className='collageLabel'>Collage Description</tt></Label>
		<div>
		  <Input type="textarea" name="description" id="textForm" className='commentForm' placeholder='Collage description' defaultValue={this.state.description} value={this.state.description} onChange={this.handleChange}/>
		</div>
	      </Form>

	      <div>
		# Rows
	      </div>
	      <div className='sizeRange'>
		<InputRange
		  maxValue={10}
		  minValue={1}
		  onChange={value => this.updateSize(value, this.state.columns)}
		  value={this.state.rows}
		  />
			   </div>

<div>
  # Columns
</div>
<div className='sizeRange'>
  <InputRange
    maxValue={10}
    minValue={1}
    value={this.state.columns}
    onChange={value => this.updateSize(this.state.rows, value)} />
			    </div>


</div>

<div className='collageInfoCol' id='albumInfoSection'>
  <FormGroup onSubmit={this.findArt}>
    <Form>
      <Label for='albumArtist'><tt className='collageLabel'>Album Artist</tt></Label>
      <div>
	<Input type="text" name="albumArtist" className='pwBox' placeholder="Artist" value={this.state.albumArtist} onChange={this.updateArtist}/>
      </div>
    </Form>

    <Form>
      <Label for='albumTitle'><tt className='collageLabel'>Album Title</tt></Label>
      <div>
	<Input type="text" name="albumTitle" className='pwBox' placeholder="Album" value={this.state.albumTitle} onChange={this.updateAlbum}/>
      </div>
    </Form>

    <Form>
      <Label for='albumNote'><tt className='collageLabel'>Album Note</tt></Label>
      <div>
	<Input type="textarea" name="albumNote" id="textForm" className='commentForm' placeholder='Optional note/description about the album' defaultValue={this.state.albumNote} value={this.state.albumNote} onChange={this.updateNote}/>
      </div>
    </Form>

    <Form>
      <Button type='submit' id="submit" onClick={this.findArt}><tt>Find Art</tt></Button>
    </Form>

  </FormGroup>
</div>

</div>

{ this.state.gotArt &&
  this.renderArtLinks()
}

<Form>
  <Button type='submit' id="submit" onClick={this.submitCollage}><tt>Submit Collage</tt></Button>
</Form>
<div className='collageVizSection'>
  {this.renderCollage()}
</div>
<div><a onClick={this.deleteCollage} href='/#' className='deleteCollage' >[ Delete ]</a></div>
</div>
      );
    }
    return <div/>
  }
}

export default EditCollage;

