import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { axios } from '../../utils/axios';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import './ViewCollage.css';

class DisplayAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artist: props.artist,
      album: props.album,
      note: props.note,
      image: props.image,
      id: props.id,
      showTooltip: false
    };
    this.getTooltip = this.getTooltip.bind(this);
  }

  componentDidMount() {
    let newState = Object.assign({}, this.state);
    newState.mounted = true;
    this.setState(newState);
  }

  getTooltip() {
    let mainStr = `Album: ${this.state.album} <br/> Artist: ${this.state.artist}`;
    if (this.state.note !== '') mainStr = `${mainStr}<br/> Note: ${this.state.note}`;
    return mainStr;
  }

  render() {
    return (
      <div>
	<div data-tip={this.getTooltip()} data-multiline={true} data-class='customTooltip' className='collageAlbum' id={this.state.id} style={{'background-image': `url(${this.state.image})`}}/>
	<ReactTooltip/>
      </div>
    );
  }
}

class ViewCollage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotData: false,
      id: props.match.params.id,
      currentUser: props.currentUser
    };
    this.unserializeCollageData = this.unserializeCollageData.bind(this);
    this.renderCollage = this.renderCollage.bind(this);
    this.renderCollageRow = this.renderCollageRow.bind(this);
    this.renderDescription = this.renderDescription.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
  }

  renderEdit() {
    if (this.state.currentUser === this.state.user) {
      return (
	<div className='collageViewEditContainer'>
	  <NavLink className='collageViewEdit' to={`/collages/edit/${this.state.id}`}>[ Edit ]</NavLink>
	</div>
      );
    }
  }

  componentDidMount() {
    axios.post('/getcollage', {id: this.state.id})
      .then( res => {
        let newState = Object.assign({}, this.state);
	newState.gotData = true;

	newState.title = res.data.title;
	newState.user = res.data.user;
	newState.description = res.data.description;
	newState.type = res.data.type;
	newState.rows = res.data.rows;
	newState.columns = res.data.columns;
	newState.id = res.data.count;
	newState.albums = this.unserializeCollageData(res.data.collageData,
						      res.data.rows,
						      res.data.columns);
	newState.gotData = true;
        this.setState(newState);
      })
      .catch( err => {
        let newState = Object.assign({}, this.state);
	newState.error = true;
        this.setState(newState);
      });
  }

  renderDescription() {
    if (this.state.description !== '') {
      return this.state.description;
    }
    return "This collage doesn't have a description yet!";
  }

  unserializeCollageData(collageData, rows, columns) {
    const formattedData = [];
    for (let i = 0; i < rows; i++) {
      formattedData.push([]);
      for (let j = 0; j < columns; j++) {
	let collage = collageData[columns*i+j];
	const id = `r${i}c${j}`;
	formattedData[i][j] = <DisplayAlbum artist={collage.artist} album={collage.album} note={collage.note} image={collage.image} id={id}/>;
      }
    }
    return formattedData;
  }

  renderCollageRow(row) {
    return (
      <div className='collageRow'>
	{row}
      </div>
    );
  }

  renderCollage() {
    const rows = [];
    this.state.albums.forEach(row => {
      rows.push(this.renderCollageRow(row));
    });
    return (
      <div className='collageMain'>
	{rows}
      </div>
    );
  }

  render() {
    if (this.state.gotData) {
      return (
	<div className='addCollageMain'>
	  <hr className='directoryHr'></hr>
	  <h1>{this.state.title}</h1>
	  {this.renderEdit()}
	  <div className='collageViewUser'> by <NavLink className='collageUser' activeClassName='activeWikiLink' to={`/profile/${this.state.user}`}>{this.state.user}</NavLink></div>
	  <div className='collageViewType'>{this.state.type}</div>
	  <hr className='directoryHr'></hr>

	  <div className='collageViewInfoSection'>
	    {this.renderDescription()}
	  </div>
	  <div className='collageVizSection'>
	    {this.renderCollage()}
	  </div>
	</div>
      );
    }
    if (this.state.error) {
      return <Redirect to='/'/>;
    }
    return (
      <div className='collagesMain'>

      </div>
  );
  }
}

export default ViewCollage;
