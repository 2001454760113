import React, { Component } from 'react';
import Logo from '../Logo';
import MainBackground from '../MainBackground';
import Banner from '../Banner';
import { Alert, Form, FormGroup, Label, Input, Button, Col } from '../../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom"

import { axios } from '../../utils/axios';

import './ResetPassword.css'

const queryString = require('query-string');

class ResetPass extends Component {
  constructor(props) {
    super(props);

    var queryStrings = queryString.parse(this.props.location.search);
    this.state = {
      password1: '',
      password2: '',
      msg: '',
      visible: false,
      success: false,
      error: false,
      response: false,
      hash: queryStrings.hash
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.get('/reset-password', {
      params: {
	hash: this.state.hash
      }
    }).then(res => {
      let newState = Object.assign({}, this.state);
      newState.response = true;
      this.setState(newState);
    }).catch(err => {
      let newState = Object.assign({}, this.state);
      newState.error = true;
      newState.response = true;
      this.setState(newState);
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    axios.post('/reset-password', {
      hash: this.state.hash,
      password1: this.state.password1,
      password2: this.state.password2
    }).then(res => {
      console.log(res);
      let newState = Object.assign({}, this.state);
      newState.success = true;
      this.setState(newState);
    }).catch(err => {
      console.log(err.response.data);
      let newState = Object.assign({}, this.state);
      newState.visible = true;
      newState.msg = err.response.data;
      this.setState(newState);
    });
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value
    this.setState(newState);
  }

  renderRedirect() {
    if (this.state.response && this.state.error) {
      return <Redirect to='/' />
    }
  }

  render() {
    let body = '';
    if (this.state.response && !this.state.error) {
      body = (
	<div className='resetBody'>
	  <Alert color="dark" className="alert" isOpen={this.state.visible}>
            <tt>{this.state.msg}</tt>
	  </Alert>
	  <div className='resetHeader'>
	    <tt>please enter a new password.</tt>
	  </div>
	  <hr className='verify-hr'/>
          <Col>

            <FormGroup >
              <Label for='password1'><tt>password</tt></Label>
              <div>
                <Input type="password" name="password1" className="pwBox" placeholder="pass" onChange={this.handleChange} onSubmit={this.handleSubmit}/>
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='password2'><tt>repeat password</tt></Label>
              <div>
                <Input type="password" name="password2" className="pwBox" placeholder="repeat pass" onChange={this.handleChange} onSubmit={this.handleSubmit}/>
              </div>
            </FormGroup>
          </Col>
	  <Form>
            <FormGroup>
              <Button id="submit" onClick={this.handleSubmit}><tt>submit</tt></Button>
            </FormGroup>
          </Form>
	</div>
      );
    }
    if (this.state.response && !this.state.error && this.state.success) {
      body = (
	<div className='resetBody'>
	  <tt>we reset your password. you can try logging in <a href="http://bool.rs">here</a> now.</tt>
	</div>
      )
    }

    return (
	<div className='Reset'>
	  {this.renderRedirect()}
          {body}
        </div>
    );
  }
}
export default ResetPass;
