import React, { Component } from 'react';
import './Banner.css'

class Banner extends Component {
  render() {
    return (
      <div id="tickerwrap">
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker"><i><pre>big beats are the best, get high all the time</pre></i></div>
        <div id="ticker-bottom"><i><pre>big beats are the best, get high all the time</pre></i></div>
      </div>
    );
  }
}
export default Banner;
