import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col, Media } from '../../../node_modules/reactstrap/es/index.js';

import { axios } from '../../utils/axios';
import './Guestbook.css';

import Comment from '../Comment';

class Guestbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: '',
      success: false,
      visible: false,
      commentText: '',
      gotComments: false,
      comments: []
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderComments = this.renderComments.bind(this);
  }

  renderComments() {
    const results = [];
    if (this.state.comments.length == 0) {
      results.push('no comments to display :(');
      return results;
    }

    this.state.comments.forEach( comment => {
      console.log(comment);
      results.push(<Comment comment={comment}/>);
    });
    return results;
  }

  componentDidMount() {
    axios.get('guestbook')
      .then( res => {
        let newState = Object.assign({}, this.state);
        newState.gotComments = true;
        newState.comments = res.data.comments;
        console.log(res.data.comments);
        this.setState(newState);
      })
      .catch( err => {
        let newState = Object.assign({}, this.state);
        newState.gotComments = true;
        this.setState(newState);
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.commentText == '') {
      let newState = Object.assign({}, this.state);
      newState.alertText = 'Please enter a comment!';
      newState.visible = true;
      this.setState(newState);
    } else if (this.state.commentText.length > 400) {
      let newState = Object.assign({}, this.state);
      newState.alertText = 'Please limit your comment to 400 characters';
      newState.visible = true;
      this.setState(newState);
    } else {
      axios.post('/guestbook', {text: this.state.commentText})
        .then( res => {
          let newState = Object.assign({}, this.state);
          newState.success = true;
          this.setState(newState);
        }).catch( err => {
          let newState = Object.assign({}, this.state);
          newState.alertText = err.response.data;
          newState.visible = true;
          this.setState(newState);
        });

    }
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState, () => {
      console.log(this.state);
    });
  }

  render() {
    if (this.state.success) {
      return (
	<div className='addCollageSuccess'>
	  <div>
	    Comment successfully added. Refresh to view.
	  </div>
	</div>
      );
    } else {
      return (
        <div id='guestbookMain'>
          <hr className='directoryHr'/>
          <h1>Guestbook</h1>
          <hr className='directoryHr'/>

          <Alert color="dark" id='commentAlert' isOpen={this.state.visible}>
            <tt>{this.state.alertText}</tt>
          </Alert>

          <Label for='commentText' id='commentHeader'>Leave a comment!</Label>
          <FormGroup>
            <div>
              <Input type="textarea" name="commentText" id="textForm" className='commentForm' placeholder='Sign our guestbook!' defaultValue={this.state.commentText} onChange={this.handleChange}/>
            </div>
          </FormGroup>

          <Form>
            <FormGroup>
              <Button id="submit" onClick={this.handleSubmit}><tt>submit</tt></Button>
            </FormGroup>
          </Form>
          <hr className='directoryHr'/>

          {this.state.gotComments &&
           <div id='commentMain'>
           {this.renderComments()}
           </div>
          }

        </div>
      );
    }
  }
}

export default Guestbook;
