import React from 'react'
import Two from 'two.js';
import ReactDOM from 'react-dom';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col } from '../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import './App.css'
import './pages/Home.css'

class MainBackground extends React.Component {

  constructor (props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="MainApp">
	<div className='MainBackground'>
	<div className='MainWrapper'>

	<Container className='HeaderContainer'>
	<Link to='/'>
        <div className="Enter">
        <div className="Title"><i><tt>b o o l . r s</tt></i></div>
        </div>
	</Link>
	<hr className='heading-hr'/>
	</Container>

	<Container className='BodyContainer'>
	{this.props.children}
	</Container>

      </div>
	</div>
	</div>
    );
  }

}

export default MainBackground
