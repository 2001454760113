const ax = require('axios');

const backend_url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:9000/api';

const axios = ax.create({
  baseURL: backend_url,
  withCredentials: true
});

export { axios, backend_url }
