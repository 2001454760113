import React, { Component } from 'react';
import { axios, backend_url } from '../../utils/axios';
import ReactMarkdown from 'react-markdown/with-html';
import { NavLink } from "react-router-dom";

import { renderers } from './WikiPage';

import './Feed.css';

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.renderPosts = this.renderPosts.bind(this);
    this.renderPost = this.renderPost.bind(this);
    this.transformImageUri = this.transformImageUri.bind(this);
  }

  transformImageUri(uri) {
    return `${backend_url}/data/images/${uri}`;
  }

  renderPost(post) {
    return (
      <div className='feedPost'>
	<div className='feedPostHeader'>

	  <div className='feedPostHeaderTitle'>
	    {post.title}
	  </div>

	  <div className='feedPostHeaderAuthor'>
	    <div style={{padding: '5px'}}>by </div>
	    <div className='feedPostAuthorLink'>
                <NavLink className='feedPostAuthorNav' to={`/profile/${post.author}`}>
		  {post.author}
		</NavLink>
	    </div>
	  </div>

	  <div className='feedPostHeaderDate'>
	    {post.date}
	  </div>

	</div>
	<div className='feedPostBody'>
	  <ReactMarkdown renderers={renderers} escapeHtml={false} transformImageUri={this.transformImageUri} source={post.data}/>
	</div>
      </div>
    );
  }

  renderPosts() {
    const postList = [];
    this.state.data.forEach(post => {
      postList.push(this.renderPost(post));
    });
    return postList;
  }

  componentDidMount() {
    axios.get('/feed')
      .then( res => {
	console.log(res);
	let newState = Object.assign({}, this.state);
	newState.data = res.data;
	newState.loaded = true;
	this.setState(newState);
      }).catch( err => {

      });
  }

  render() {
    if (this.state.loaded) {
      return (
	<div className='feedPostContainer'>
	  <hr className='directoryHr'/>
          <h1>Home</h1>
          <hr className='directoryHr'/>
	  {this.renderPosts()}
	</div>
      );
    }
    return null;
  }
}

export default Feed;
