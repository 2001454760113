import React, { Component } from 'react';
import { Alert, Form, FormGroup, Label, Input, Button } from '../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { axios } from '../utils/axios';

import './pages/Home.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      msg: '',
      visible: false,
      status: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    axios.post('/login', {
      username: this.state.username,
      password: this.state.password})
      .then(res => {
	let newState = Object.assign({}, this.state);
        newState.msg = res.data;
	newState.visible = true;
        newState.status = res.status;
	this.setState(newState);
      }).catch(err => {
	console.log(err.response.data);
	let newState = Object.assign({}, this.state);
	newState.msg = err.response.data;
	newState.visible = true;
        newState.status = err.response.status;
	this.setState(newState);
      });
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  onKeyPress(target) {
    if (target.charCode == 13) {
      this.onSubmit(target);
    }
  }
  render() {
    if (this.props.location.pathname !== '/') {
      return <Redirect to='/'/>;
    }
    if (this.state.status === 200) {
      window.location.reload();
    } else {
      return (
        <div className="pwForm">
          <Alert color="dark" className="alert" isOpen={this.state.visible}>
            <tt>{this.state.msg}</tt>
	  </Alert>
          <div id="loginMsg">
            <tt>please enter your credentials</tt>
          </div>
          <Form className="form">
            <FormGroup>
              <Input type="username" name="username" className="pwBox" placeholder="user" onChange={this.handleChange} onKeyPress={this.onKeyPress}/>
            </FormGroup>
            <FormGroup>
              <Input type="password" name="password" className="pwBox" placeholder="pass" onChange={this.handleChange} onKeyPress={this.onKeyPress}/>
            </FormGroup>
            <FormGroup>
              <Button id="submit" onClick={this.onSubmit}>enter</Button>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <Link to='/invite'>
                <Button id="invite">request an invite</Button>
              </Link>
            </FormGroup>
          </Form>
          <div id="forgotStuff">
            <Form>
              <FormGroup className='forgotRow'>
                <Link to='/forgotlogin'>
                  <Button id="forgot">i forgot my username!</Button>
                </Link>
                <Link to='/forgotpass'>
                  <Button id="forgot2">i forgot my password!</Button>
                </Link>
              </FormGroup>
            </Form>
          </div>
        </div>
      );
    }
  }
}

export default Login;
