import React, { Component } from 'react';

class Inbox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return null;
  }
}

export default Inbox;
