import { axios } from './axios';

function loggedIn(cb) {
  axios.get('logged-in')
    .then( res => {
      cb(true, res.data.username);
    }).catch( err => {
      cb(false, undefined);
    });
}

export default loggedIn;
