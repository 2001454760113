import React, { Component } from 'react';

class RenderTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tracker: props.tracker
    };
  }

  render() {
    return (
      <div className='trackerMain'>
        <div className='renderTrackerItem'>
          {this.state.tracker.abbr}
        </div>
        <div className='renderTrackerItem'>
          {this.state.tracker.username}
        </div>
        <div className='renderTrackerItem'>
      {this.state.tracker.invites ? 'Yes' : 'No'}
        </div>
      </div>
    );
  }
}

export default RenderTracker;
