import React, { Component } from 'react';
import Logo from '../Logo';
import MainBackground from '../MainBackground';
import Banner from '../Banner';
import { Form, FormGroup, Label, Input, Button } from '../../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { axios } from '../../utils/axios';


import './Verify.css';

import queryString from 'query-string';


class Verify extends Component {
  constructor(props) {
    super(props);

    var queryStrings = queryString.parse(this.props.location.search);
    console.log(queryStrings);
    this.state = {
      admin: false,
      response: false,
      error: false,
      comment: ' ',
      email: '',
      username: '',
      hash: queryStrings.hash,
      showCommentForm: false,
      makeAdmin: false,
      responseComment: '',
      done: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.get('/auth', {
      params: {
	hash: this.state.hash
      }
    }).then(res => {
      let newState = Object.assign({}, this.state);
      newState.comment = res.data.comment;
      newState.email = res.data.email;
      newState.username = res.data.username;
      newState.response = true;
      this.setState(newState);
    }).catch(err => {
      let newState = Object.assign({}, this.state);
      newState.error = true;
      newState.response = true;
      this.setState(newState);
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let accept;
    if (e.target.id === 'accept')
      accept = true;
    else
      accept = false;

    axios.post('/auth', {
      hash: this.state.hash,
      makeAdmin: this.state.admin,
      respondWithComment: this.state.showCommentForm,
      comment: this.state.responseComment,
      accept: accept
    })
      .then( res => {
	// Update state, show success message
	let newState = Object.assign({}, this.state);
	newState.done = true;
	this.setState(newState);
      }).catch( err => {
	console.log(err.response.data);
      });
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  onClick(e) {
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = !newState[e.target.name];
    this.setState(newState);
  }

  renderRedirect() {
    if (this.state.response && this.state.error) {
      return <Redirect to='/' />;
    }
  };

  renderCommentForm() {
    if (this.state.showCommentForm)
      return (
        <FormGroup>
          <div>
            <Input type="textarea" name="responseComment" id="textForm" placeholder='response comment.'onChange={this.handleChange}/>
          </div>
	</FormGroup>
      );
  };

  render() {
    let body = '';
    if (this.state.response && !this.state.error && !this.state.done) {
      body = (
	<div className='verifyBody'>
	  <div className='verifyHeader'>
	    <div>
	      <h1>
	        please review the following invite request.
	      </h1>
	    </div>
	  </div>

	  <hr className='verify-hr'/>
	  <div className='verifyContent'>
	    <br/>
	    <div>
	      Username: {this.state.username}
	    </div>
	    <br/>
	    <div>
	      Email: {this.state.email}
	    </div>
	    <br/>
	    <div>
	      Comment: {this.state.comment}
	    </div>
	  </div>
	  <hr className='verify-hr'/>

	  <div id='verifyCheckboxes'>
	    <FormGroup check>
              <Label check>
                <Input name="admin" type="checkbox" onClick={this.onClick}/>{' '}
	        Make admin?
              </Label>
            </FormGroup>

	    <FormGroup check>
              <Label check>
                <Input name="showCommentForm" type="checkbox" onClick={this.onClick}/>{' '}
	        Respond with comments?
              </Label>
            </FormGroup>
	  </div>
	  {this.renderCommentForm()}

	  <div id='submitForms'>
            <Form>
              <FormGroup>
                <Button id="accept" className='submit' onClick={this.handleSubmit}>accept</Button>
              </FormGroup>
            </Form>
	    <Form>
              <FormGroup>
                <Button id="reject" className='submit' onClick={this.handleSubmit}>reject</Button>
              </FormGroup>
            </Form>
	  </div>
	</div>
      );
    }

    if (this.state.done) {
      return (
	  <div className='Verify'>
	  Thanks. Your response has been recorded.
        {body}
        </div>
      );
    }

    return (
	<div className='Verify'>
	  {this.renderRedirect()}
          {body}
        </div>
    );
  }
}
export default Verify;
