import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Two from 'two.js';
import Color from 'color';
import collide from 'line-circle-collision';

class Background extends Component {
  constructor (props) {
    super(props);
    this.state = {
      y1: window.innerHeight / 2,
      y2: window.innerHeight / 2,
      y3: window.innerHeight / 2,
      right: 0,
      bottom: 0,
    };

    this.line = null;
    this.two = null;

  }

  componentWillMount() {
    var two = new Two({
      type: Two.Types['canvas'],
      fullscreen: true,
      autostart: true
    });
    this.two = two;
  }

  componentDidMount() {
    var two = this.two;
    two.appendTo(ReactDOM.findDOMNode(this.refs.stage))
      .trigger('resize')
      .update();
    var colors = [
      'rgb(255, 64, 64)',
      'rgb(255, 128, 0)',
      'rgb(0, 200, 255)',
      'rgb(0, 191, 168)',
      'rgb(153, 102, 255)',
      'rgb(255, 244, 95)'
    ];
    colors.index = 0;
    this.colors = colors;

    var linearGradient = two.makeLinearGradient(
      two.width / 2, - two.height / 2,
      two.width / 2, two.height / 2,
      new Two.Stop(0, Color(colors[0]).desaturate(.5).string()),
      new Two.Stop(1, Color(colors[5]).desaturate(.5).string()),
    );


    //linearGradient.spread = 'reflect';
    this.linearGradient = linearGradient;
    var rectangle = two.makeRectangle(two.width / 2, two.height / 2, two.width, two.height);
    rectangle.noStroke();

    rectangle.fill = linearGradient;
    this.rectangle = rectangle;

    this.circleRadius = 40;
    var circle = two.makeCircle(0, 0, this.circleRadius);
    circle.fill = 'black';
    this.circle = circle;
    var text = two.makeText("boolin'", 0, 0)
    text.fill = "white";
    text.size = 12;

    var hiddenText = two.makeText('ブール', two.width / 2, two.height / 2, 'italic');
    hiddenText.size = two.width/3.5;
    hiddenText.opacity = 0;
    this.hiddenText = hiddenText;

    var group = two.makeGroup(circle, text);
    group.translation.set(two.width / 2, two.height / 2);
    this.group = group;
    this.groupDirection = new Two.Vector(Math.random(), Math.random()).normalize();
    this.groupVelocity = 15;

    this.displayingText = false;
    this.displayStartFrame = -100;

    two.bind('update', frameCount => {
      for (var i = 0; i < this.linearGradient.stops.length; i++) {
        var col = Color(this.linearGradient.stops[i].color);
        this.linearGradient.stops[i].color = col.rotate(1).rgb().string()
      }
      this.group.rotation = frameCount/30;
      this.group.translation.set(this.group.translation.x + this.groupDirection.x*this.groupVelocity,
                                 this.group.translation.y + this.groupDirection.y*this.groupVelocity);

      var circlePos = [this.group.translation.x, this.group.translation.y];

      var topLeft = [0,0]
      var topRight = [two.width, 0]
      var bottomLeft = [0, two.height]
      var bottomRight = [two.width, two.height]

      var hit = true;
      if (collide(topLeft, topRight, circlePos, this.circleRadius)) {
        this.groupDirection = new Two.Vector(this.groupDirection.x, -this.groupDirection.y)
      } else if (collide(topRight, bottomRight, circlePos, this.circleRadius)) {
        this.groupDirection = new Two.Vector(-this.groupDirection.x, this.groupDirection.y)
      } else if (collide(bottomRight, bottomLeft, circlePos, this.circleRadius)) {
        this.groupDirection = new Two.Vector(this.groupDirection.x, -this.groupDirection.y)
      } else if (collide(bottomLeft, topLeft, circlePos, this.circleRadius)) {
        this.groupDirection = new Two.Vector(-this.groupDirection.x, this.groupDirection.y)
      } else {
        hit = false;
      }

      if (hit) {
        //this.audio.pause();
        //this.audio.currentTime = 0;
        //this.audio.play();
        this.displayingText = true;
        this.displayStartFrame = frameCount
        console.log('showing hidden text!')
      }
      if (this.displayingText = true && (frameCount - this.displayStartFrame) < 1) {
        this.hiddenText.opacity = 1;
      } else {
        this.hiddenText.opacity = 0;
      }
    });
    two.bind('resize', () => {
      var w = this.two.width / 2;
      var h = this.two.height / 2;

      this.linearGradient.left.y = - h;
      this.linearGradient.right.y = h;

      this.rectangle.vertices[0].set(- w, - h);
      this.rectangle.vertices[1].set(w, - h);
      this.rectangle.vertices[2].set(w, h);
      this.rectangle.vertices[3].set(- w, h);

      this.rectangle.translation.set(w, h);
    });
  }

  // frac is 0, return col1, frac is 1 return col2
  getIntermediateColor(col1, col2, frac) {
    var newCol1 = Color(col1);
    var newCol2 = Color(col2);
    return newCol1.mix(newCol2, frac).rgb().string();
  }

  componentWillUnmount() {
    this.two.unbind('resize');
  }

  componentWillUpdate(nextProps, nextState) {
    for (var i = 0; i < 3; i++) {
      var y = 'y' + (i+1);
      if (this.state[y] !== nextState[y]) {
        this.line.vertices[i].y = window.innerHeight / 2 - this.state[y];
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.y1, this.state.y2, this.state.y3, this.state.bottom)
    this.two.update();
  }

  render() {
    return (
        <div>
        <div ref="stage" style={{height: 100 + '%'}}></div>
        </div>
    );
  }
}

export default Background;
