import React, { Component } from 'react';
import { axios } from '../../utils/axios';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import './Collages.css';

class Collages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotData: false,
      data: []
    };
    this.renderTable = this.renderTable.bind(this);
  }

  componentDidMount() {
    console.log('mounted');
    axios.get('/getcollages')
      .then( res => {
        let newState = Object.assign({}, this.state);
	console.log(res.data);
	newState.gotData = true;
        newState.data = res.data.reverse();
        this.setState(newState);
      })
      .catch( err => {
	console.log(err);
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
	newState.error = true;
        this.setState(newState);
      });
  }

  renderTable() {
    const wikiLinks = [];
    let colFlag = true;
    const styleA = 'rgba(.4, .4, .4, .1)';
    const styleB = 'rgba(.4, .4, .4, .2)';
    this.state.data.forEach(link => {
      wikiLinks.push(
	<tr className='collageTableRow' style={{background: colFlag ? styleA : styleB}}>
	  <td>{link.type}</td>
	  <td className='collageNameCell'><NavLink className='collageName' activeClassName='activeWikiLink' to={`/collages/view/${link.id}`}>{link.title}</NavLink></td>
	  <td><NavLink className='collageUser' activeClassName='activeWikiLink' to={`/profile/${link.user}`}>{link.user}</NavLink></td>
	</tr>
      );
      colFlag = !colFlag;
    });
    return wikiLinks;
  }


  render() {
    return (
      <div className='collagesMain'>
	<hr className='directoryHr'></hr>
	<h1>Collages</h1>
	<hr className='directoryHr'></hr>
	<div className='collageNavRow'>
	  <NavLink className='newCollageLink' activeClassName='collageLinkActive' to='/collages/add'>New Collage</NavLink>
	</div>
	<hr id='smallHr'/>
	{ this.state.gotData &&
	  <div className='linkContainer'>
	      <table className='collageTable'>
		  <tr className='collageTableHeader'>
		      <td>Category</td>
			<td>Collage</td>
			  <td>Author</td>
		    </tr>
		  {this.renderTable()}
		</table>
	    </div>
	}
      </div>
  );
  }
}

export default Collages;
