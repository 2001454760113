import React, { Component } from 'react';
import './Logo.css'

class Logo extends Component {
  render() {
    return (
    <div className="Logo">
	boolin'
    </div>
    );
  }
}
export default Logo;
