import React, { Component } from 'react';
import Login from './Login';
import Invite from './Invite';
import ForgotUser from './ForgotUsername';
import ForgotPass from './ForgotPassword';
import Banner from './Banner';
import Verify from './pages/Verify';
import ResetPass from './pages/ResetPassword';

import { Form, FormGroup, Label, Input, Button, Container } from '../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

import './pages/Home.css';

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {

    if (this.state.redirect) {
      return <Redirect to='/'/>;
    }

    return (
      <div className="App">

        <div className="EnterWrapper">

      	  <Container className='HeaderContainer'>

	    <Link to='/'>
              <div className="Enter">
                <div className="Title"><i><tt>check 'em</tt></i></div>
              </div>
	    </Link>
	    <hr/>
	    <div className="Subtitle">
              <tt><i>the first and <b>only</b> website</i></tt>
            </div>
	    <hr/>
	  </Container>
          <Switch>
            <Route exact path='/invite' component={Invite}/>
            <Route exact path='/forgotpass' component={ForgotPass}/>
            <Route exact path='/forgotlogin' component={ForgotUser}/>
            <Route exact path='/auth' component={Verify}/>
            <Route exact path='/resetpass' component={ResetPass}/>
            <Route path='/' component={Login}/>
          </Switch>
          <div/>
        </div>
      </div>
    );
  }
}
export default Splash;
