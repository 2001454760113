import React, { Component } from 'react';
import { axios, backend_url } from '../../utils/axios';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";
import ReactMarkdown from 'react-markdown/with-html';

import './WikiPage.css';

const renderers = {
  paragraph: props => <div className='mdParagraph'>{props.children}</div>,
  image: props => <a href={props.src} target="_blank"><img src={props.src} alt={props.alt} className='mdImage'/></a>,
  thematicBreak: props => <hr id='smallHr'/>,
  list: props => <ol className='mdOrderedList'>{props.children}</ol>,
  listItem: renderList
};

function renderList(props) {

  let number = '•';
  if (props.ordered) {
    number = props.index + 1;
  }
  return (
    <div className='mdLI'>
      <div className='mdLINumber'>{number}</div>
      <div className='mdLIContents'>{props.children}</div>
    </div>
  );
}
class WikiPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.match.params.page,
      gotData: false,
      data: ''
    };

    this.transformImageUri = this.transformImageUri.bind(this);
  }

  componentDidMount() {
    console.log(`wiki/${this.state.page}`);
    axios.get(`wiki/${this.state.page}`)
      .then( res => {
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
        newState.data = res.data;
	console.log(res.data);
        this.setState(newState);
      })
      .catch( err => {
        let newState = Object.assign({}, this.state);
	newState.gotData = true;
	newState.error = true;
        this.setState(newState);
      });
  }

  transformImageUri(uri) {
    return `${backend_url}/data/images/${uri}`;
  }

  render() {
    return (
      <div className=''>
	<hr className='directoryHr'></hr>
	{ this.state.gotData &&
	  <div className='markdownWrapper'>
	      <ReactMarkdown renderers={renderers} escapeHtml={false} transformImageUri={this.transformImageUri} source={this.state.data}/>
	    </div>
	}
      </div>
  );
  }
}

export default WikiPage;
export {
  renderers
}
