import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col, Media } from '../../../node_modules/reactstrap/es/index.js';

import { axios } from '../../utils/axios';
import htmlDecode from '../../utils/htmlDecode';

import './Profile.css';

import RenderTracker from '../RenderTracker';

class TrackerHeader extends Component {
  render () {
    return (
      <div className='trackerMain'>
        <div className='renderTrackerItem'>
          <b>Tracker</b>
        </div>
        <div className='renderTrackerItem'>
          <b>Username</b>
        </div>
        <div className='renderTrackerItem'>
          <b>Invites</b>
        </div>
      </div>
    );
  }
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.match.params.user,
      gotProfile: false,
      error: false,
    };

    this.renderBio = this.renderBio.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
    this.renderTrackers = this.renderTrackers.bind(this);
    this.renderCollages = this.renderCollages.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  getProfile(){
    axios.get(`profile/${this.state.username}`)
      .then( res => {
        let newState = Object.assign({}, this.state);
        newState = Object.assign(newState, res.data);
        newState.gotProfile = true;
        this.setState(newState);
      }).catch( err => {
        let newState = Object.assign({}, this.state);
        newState.error = true;
        this.setState(newState);
      });
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.match.params.user != this.state.username) {
      const newState = {
        username: nextProps.match.params.user,
        gotProfile: false,
        error: false,
        bio: undefined,
        trackers: undefined,
        location: undefined,
        tag: undefined,
        imageUrl: undefined,
        admin: undefined
      };
      this.setState(newState, () => {
        console.log('set state');
        console.log(this.state);
        this.getProfile();
      });
    }

  }

  componentDidMount() {
    this.getProfile();
  }

  renderLocation() {
    let str;
    if (!this.state.location || this.state.location == '') {
      str = '<i>unknown!</i>';
    } else {
      str = this.state.location;
    }
    return {__html: str};
  }

  renderBio() {
    let str;
    if (!this.state.bio || this.state.bio == '') {

      str = `${this.state.username} hasn't written a bio yet!`;
    } else {
      console.log(this.state.bio);
      str = this.state.bio;
    }
    return {__html: str};
  }

  renderTrackers() {
    if (!this.state.trackers || this.state.trackers.length == 0) {
      return <i>this nerd hasn't added any trackers yet.</i>;
    }
    const result = [<TrackerHeader/>, <hr className='profileHr'/>];
    this.state.trackers.forEach(tracker => {
      result.push(<RenderTracker tracker={tracker}/>);
    });
    return result;
  }

  renderCollages() {
    if (this.state.collages.length == 0) return;

    const collages = this.state.collages.map(collage =>
      	<NavLink className='profileCollageView' to={`/collages/view/${collage.id}`}>- {collage.title}</NavLink>
    );
    return (
      <div className='collageProfileSection'>
	<div className='collageProfileTitle'>collages:</div>
	{collages}
      </div>
    );
  }

  render() {
    if (!this.state.gotProfile && !this.state.error) {
      return null;
    } else if (this.state.error && !this.state.gotProfile) {
      return <Redirect to="/"/>;
    } else {
      return (
        <div id='profileMain'>
          <hr className='profileHr'/>
          <div id='profileHeader'
          >
          <div id='usernameLine'>
            <h1>
              <i>{this.state.username}</i>
            </h1>
          </div>
          { this.state.tag &&
            <div id='tagLine'>
              {this.state.tag}
            </div>
          }
          { this.state.admin &&
            <div id='adminLine'>
              admin
            </div>}
        </div>
          <hr className='profileHr'/>

          <div id='colSec'>
            <div id='bioCol'>
              <div id='bioSection'>
                <div id='bioText' dangerouslySetInnerHTML={this.renderBio()}/>
              </div>
              <hr className='profileHr'/>
              <div id='trackerSection'>
                {this.renderTrackers()}
              </div>
              <hr className='profileHr'/>
            </div>

            <div id='infoCol'>
              { this.state.imageUrl &&
                <img className='profileImage' src={this.state.imageUrl}/>
              }
		<hr className='profileHr'/>
		<div id='location'>
                  <div id='locationHeader'>
                    mail: {this.state.username}@bool.rs
                  </div>
		</div>
		<hr className='profileHr'/>
              <div id='location'>
                <div id='locationHeader'>
                  location:
                </div>
                <div dangerouslySetInnerHTML={this.renderLocation()}/>
              </div>
              <hr className='profileHr'/>
	      {this.renderCollages()}
	      { this.state.collages.length !== 0 &&
		<hr className='profileHr'/>
	      }
            </div>
          </div>

        </div>
      );
    }
  }
}

export default Profile;
