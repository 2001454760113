import React, { Component } from 'react';
import { axios } from '../../utils/axios';
import { Label, FormGroup, Input, Form, Button, Alert } from 'reactstrap';
import './BoolAlert.css';

class BoolAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boolInfo: '',
      visible: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    if (this.state.boolInfo.length < 20) {
      const newState = Object.assign({}, this.state);
      newState.visible = true;
      newState.alertText = 'Please enter a longer description (min 20 char).';
      this.setState(newState);
    } else {
      axios.post('/boolalert', {info: this.state.boolInfo})
	.then( res => {
	  const newState = Object.assign({}, this.state);
	  newState.success = true;
	  this.setState(newState);
	}).catch( err => {
	  const newState = Object.assign({}, this.state);
	  newState.visible = true;
	  newState.alertText = 'There was an error while sending your bool alert.';
	  this.setState(newState);
	});
    }
  }

  handleChange(e) {
    e.preventDefault();
    const newState = Object.assign({}, this.state);
    newState.boolInfo = e.target.value;
    this.setState(newState);
  }

  render() {
    if (this.state.success) {
      return (
	<div className='addCollageSuccess'>
	  <div>
	    Bool alert sent. Nice going!
	  </div>
	</div>
      );
    }
    return (
	<div className='feedPostContainer'>
	  <hr className='directoryHr'/>
	  <div className='boolAlert'>
            <h1>bool alert</h1>
	    </div>
          <hr className='directoryHr'/>
	  <div className='alertBodySection'>
	    <Alert color="dark" id='commentAlert' isOpen={this.state.visible}>
              <tt>{this.state.alertText}</tt>
            </Alert>
	    <div className='alertInfoSection'>
	      <p>A bool alert will send an email to <i>everyone</i> on the site. Use only in <b>bool emergencies</b>!!!</p>
	      <p>Bool alerts are for letting everyone know about an imminent <i>bool</i>. Make sure you include information about the time and location.</p>
	    </div>

	    <Label for='boolInfo' id='commentHeader'>bool info</Label>
            <FormGroup>
              <div>
		<Input type="textarea" name="boolInfo" id="textForm" className='boolInfoForm' placeholder='Enter bool info here.' defaultValue={this.state.boolInfo} onChange={this.handleChange}/>
              </div>
            </FormGroup>

	    <Form>
            <FormGroup>
              <Button id="submit" onClick={this.handleSubmit}><tt>fire away</tt></Button>
            </FormGroup>
          </Form>
	  </div>
      </div>
    );
  }
}

export default BoolAlert;
