import React, { Component } from 'react';

import { axios } from '../../utils/axios';
import { Alert, Form, FormGroup, Label, Input, Button, Container, Col } from '../../../node_modules/reactstrap/es/index.js';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";

import './Bandcamp.css';
import BandcampAlbum from '../BandcampAlbum';

class Bandcamp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      alertText: '',
      first: 0,
      last: 20,
      albums: []
    };

    this.getAlbums = this.getAlbums.bind(this);
    this.renderAlbums = this.renderAlbums.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  getAlbums(e) {
    e.preventDefault();
    let newState = Object.assign({}, this.state);
    newState.alertText = 'we\'re working on it... hold on.';
    newState.visible = true;
    this.setState(newState, () => {
      axios.get('/getalbums', {
        params: {
          first: this.state.first,
          last: this.state.last
        }
      }).then( res => {
        let newState = Object.assign({}, this.state);
        newState.albums = res.data.albumInfo;
        newState.alertText = 'there, we did it.';
        newState.visible = true;
        this.setState(newState);
      }).catch( err => {
        let newState = Object.assign({}, this.state);
        console.log(err.response);
        newState.alertText = err.response.data;
        newState.visible = true;
        this.setState(newState);
    });
    });
  }

  renderAlbums() {
    const results = [];
    if (this.state.albums.length == 0) {
      results.push(<h3>no albums yet. have you tried <i>fetching some</i>???</h3>);
      results.push(<hr className='bandcampHr'/>);
      return results;
    }

    this.state.albums.forEach( album => {
      results.push(<BandcampAlbum album={album}/>);
      results.push(<hr className='bandcampHr'/>);
    });
    return results;
  }

  render() {
    return (
      <div id='bandcamp'>
        <hr className='bandcampHr'/>
        <h1>Bandcamp Downloader</h1>
        <hr className='bandcampHr'/>
        <Alert color="dark" id='bcdlAlert' isOpen={this.state.visible}>
          <tt>{this.state.alertText}</tt>
        </Alert>
        <div>
          <div>Select the range of most-recent albums you'd like to grab.</div>

          <div>This will only return freely downloadable albums within this range.</div>
          <div className='selectRange'>
            <Input type="text" name="first" className="rangeForm" placeholder="0" onChange={this.handleChange}/>
            thru
            <Input type="text" name="last" className="rangeForm" placeholder="20" onChange={this.handleChange}/>
          </div>
          <Button id="submit" onClick={this.getAlbums}><tt>fetch</tt></Button>
        </div>
        <hr className='bandcampHr'/>
        {this.renderAlbums()}
      </div>
    );
  }
}

export default Bandcamp;
